'use strict';

/**
 * Class to handle locking an unlocking of buttons.
 * @private
 */
class _BtnHandler {
  constructor() {
    // Do nothing
  }

  /**
   *
   * @param obj
   * @returns {any}
   * @private
   */
  _findForm(obj) {
    return obj.is('button, :submit') ? obj.closest('form') : obj;
  }


  /**
   * NOTE: Added the check to see if the value of the diabled attribute is
   * correct b/c the attribute was being added to elements after a refresh with
   * an empty value.
   * @param id
   * @returns {*|boolean}
   */
  isLocked(id) {
    //console.log(typeof id);

    let me = (typeof id === 'string') ? $(id) : id;

    //console.log(me);

    return me.is('[disabled]') && me.attr('disabled') === 'disabled';
  }

  /**
   *
   */
  lock() {
    let args = arguments;
    let container = null;

    for (let i in args) {
      if (args[i] === null) {
        return;
      }

      if (typeof args[i] === 'function') {
        continue;
      }

      if (this.isLocked(args[i])) {
        continue;
      }

      // If the argument is an object, then find its parent and search for
      // all buttons to lock in the group.
      let objs = [];

      if (typeof args[i] !== 'object') {
        let obj = $(args[i]);

        container = this._findForm(obj);
        objs.push(obj);
      } else {
        container = this._findForm(args[i]);
        container
          .find('button, :submit, :checkbox')
          .each(function () {
            objs.push($(this));
          });
      }

      for (let j in objs) {
        if (objs[j].is('button, :submit, :checkbox')) {
          objs[j]
            .addClass('btn-grey')
            .removeClass('btn-orange');
        }

        objs[j].attr('disabled', 'disabled');
      }
    }

    if (container) {
      container
        .find('.inline-message')
        .hide()
        .end()
        .find('.spinner')
        .removeClass('hide');
    }
    // @TODO: figure out a better way to handle finding the function iwthout it firing twice.
    for (let i in args) {
      if (typeof args[i] === 'function') {
        args[i]();
        break;
      }
    }
  }

  /**
   *
   */
  unlock() {
    let args = arguments;
    let container = null;

    for (let i in args) {
      if (args[i] === null) {
        return;
      }

      if (typeof args[i] === 'function') {
        continue;
      }

      // If the argument is an object, then find its parent and search for
      // all buttons to lock in the group.

      let objs = [];

      if (typeof args[i] !== 'object') {
        let obj = $(args[i]);

        container = this._findForm(obj);
        objs.push(obj);
      } else {
        container = this._findForm(args[i]);
        container
          .find('button, :submit, :checkbox')
          .each(function () {
            objs.push($(this));
          });
      }

      for (let j in objs) {
        if (objs[j].is('button, :submit, :checkbox')) {
          objs[j]
            .addClass('btn-orange')
            .removeClass('btn-grey');
        }

        objs[j].removeAttr('disabled');
      }
    }

    if (container) {
      container
        .find('.spinner')
        .addClass('hide');
    }

    for (let i in args) {
      if (typeof args[i] === 'function') {
        args[i]();
        break;
      }
    }
  }
}

FWTK.modules.listing = function (b) {
  const RC = this;
  const TRUE = true;
  const FALSE = false
  const NULL = null;
  const UNDEFINED = 'undefined';

  let BODY_TAG;
  let PAGE_KEY;

  /**
   *
   * @private
   */
  function _restripeRows() {
    let odd = TRUE;
    let zebra;
    let suffix = '_row';

    $('.row').each(function () {
      let THIS = $(this);

      if (THIS.hasClass('title_row') || THIS.hasClass('today') || THIS.hasClass('active_event')) {
        return;
      }

      if (!THIS.hasClass('delete')) {
        THIS.removeClass('odd' + suffix + ' even' + suffix);
        if (odd === TRUE) {
          zebra = 'odd' + suffix;
          odd = FALSE;
        } else {
          zebra = 'even' + suffix;
          odd = TRUE;
        }
        THIS.addClass('row ' + zebra);
      }
    });
  }

  /**
   *
   * @param id
   * @private
   */
  function _clearForm(id) {
    $(id).find('form').trigger('reset');
  }

  /**
   *
   * @param modal_id
   * @private
   */
  function _closePopup(modal_id) {
    if (modal_id !== '#editaccount1') {
      _clearForm(modal_id);
    }

    $(modal_id).dialog('close');
  }

  /**
   *
   * @param modal_id
   * @private
   */
  function _openPopup(modal_id) {
    _clearForm(modal_id);
    $(modal_id).dialog().dialog('open');
  }

  /**
   *
   * @param result
   * @returns {boolean}
   * @private
   */
  function _isSuccess(result) {
    if (result['success'] !== TRUE) {
      $('div.errormsg', 'div.modal').show();

      let form = $('div.modal:visible').find('form');
      let message = '';
      let error_class = 'modal-input-error';

      form.find('.' + error_class).removeClass(error_class);

      if (form.length && result['errors']) {
        for (let i in result['errors']) {
          form.find('[name="' + i + '"]').addClass(error_class);
          message += (message ? ', ' : '') + result['errors'][i];
        }
        message = '<strong>Please correct the following:</strong> ' + message;
      } else if (result['message']) {
        message = result['message'];
      }

      $('span.errordetails', 'div.modal').html(message);
      return FALSE;
    }

    return TRUE;
  }

  function _isSuccessPageForm(result, submit) {
    let form = submit.parents('form');
    let err_popup = form.find('.errormsg');
    let message = '';
    let error_class_name = 'modal-input-error';
    let error_class = '.' + error_class_name;

    form
      .find(error_class)
      .removeClass(error_class_name)
      .off('change');

    err_popup.hide();

    if (result['success'] !== TRUE) {
      if (form.length && result['errors']) {
        for (let i in result['errors']) {
          form
            .find('[name="' + i + '"]')
            .addClass(error_class_name);

          message += (message ? ', ' : '') + result['errors'][i];
        }

        message = `<strong>Please correct the following:</strong>${message}`;
      }

      err_popup
        .find('span.errordetails')
        .html(message)
        .end()
        .show();

      form
        .find(error_class)
        .on('change', function () {
          let parent_form = $(this).closest('form');

          parent_form.find(error_class).each(function () {
            let me = this;
            if (me.defaultValue === me.value) {
              $(me)
                .removeClass(error_class_name)
                .off('change');
            }
          });

          // if no errors are detected on the form, remove the error message container
          if (!parent_form.find(error_class_name).length) {
            parent_form
              .find('.errormsg')
              .hide();
          }
        });

      return FALSE;
    }

    return TRUE;
  }

  /**
   * This will return the current form as a JSON object.
   * @private
   */
  function _getJsonForm(form) {
    let o = {};
    let a = _getSerializedForm(form);

    $.each(a, function () {
      let that = this;
      if (!_typeOf(o[that.name], UNDEFINED)) {
        if (_typeOf(o[that.name].push, UNDEFINED)) {
          o[that.name] = [o[that.name]];
        }
        o[that.name].push(that.value || '');
      } else {
        o[that.name] = that.value || '';
      }
    });

    return o;
  }

  /**
   * Get the current form serialized.
   * @private
   * @returns {NULL|JSON}
   */
  function _getSerializedForm(form) {
    return form.serializeArray();
  }

  /**
   * Simple wrapper to handle faild ajax requests.
   * @param request
   * @private
   */
  function _handleAjax(request) {
    if (!request.error) {
      request.error = function () {
      };
    }

    if (!request.buttons) {
      request.buttons = NULL;
    }

    try {
      b.makeRequest(
        request.info,
        function (result) {
          request.success(result);
        },
        function (jqXHR, textStatus, errorThrown) {
          request.error(jqXHR, textStatus, errorThrown);
        }
      );
    } catch (err) {
      console.log(err.message);
      // @TODO: Revisit this so it takes an object and an array.
      if (request.buttons) {
        for (let i in request.buttons) {
          RC.enableButtons(request.buttons[i]);
        }
      }
    }
  }

  /**
   *
   * @private
   */
  function _editMyAccount() {
    let form = $('#edit_my_acount_form');
    let username = $('.userName');
    let err_msg = $('.errormsg');
    let succ_msg = $('.successmsg');

    $('#btn_edit_account').click(function (e) {
      e.preventDefault();

      let id = '#' + this.id;

      if (RC.BTN_HANDLER.isLocked(id)) {
        return;
      }

      let cancel_id = '#btn_edit_account_cancel';
      let record = RC.getJsonForm(form);

      _disableButtons(id, cancel_id);

      b.makeRequest(
        {
          data: record,
          url: '/accounts/personal/edit/'
        },
        function (result) {
          if (result.token) {
            form.find('input[name=is_submitted]').val(result.token);
          }

          if (!_isSuccess(result)) {
            _enableButtons(id, cancel_id);
            return;
          }

          let user_id = result['data']['user_id'];

          username.html(record['user_firstname']);

          let user_row = $(`div[data-record-id=${user_id}]`);
          let LIGHTGREY = 'lightgrey';

          user_row
            .find('.user_email')
            .html(`${record['user_firstname']} ${record['user_lastname']} (<a href="mailto:">${record['user_email']}</a>)`);

          user_row = user_row.find('.phonenumber');

          if ($.trim(record['user_phone']) === '') {
            user_row.removeClass(LIGHTGREY).addClass(LIGHTGREY).html('No Phone #');
          } else {
            user_row.removeClass(LIGHTGREY).html(record['user_phone']);
          }

          err_msg.fadeOut();
          succ_msg.fadeIn();

          setTimeout(function () {
            _closePopup('#editaccount1');
            _enableButtons(id, cancel_id);
            succ_msg.hide();
          }, 2000);
        }
      );

      return FALSE;
    });
  }

  /**
   *
   * @returns {*}
   * @private
   */
  function _globalModals() {
    let modal_id = '';
    let err_div = $('div.errormsg', 'div.modal');
    let succ_div = $('div.successmsg', 'div.modal');
    let args = arguments;
    let open_callback = (args[0] && args[0].open) ? args[0].open : () => {
      return true;
    };
    let close_callback = (args[0] && args[0].close) ? args[0].close : () => {
      return true;
    };

    return b.modal({
      openCallback: function (el) {
        let e = $(el);

        if (!open_callback(e)) {
          return;
        }

        modal_id = e.attr('rel');

        err_div.hide();
        succ_div.hide();

        $(modal_id).find('.modal-input-error').removeClass('modal-input-error');

        return FALSE;
      },
      closeCallback: function (e) {
        if (!close_callback(e)) {
          return;
        }

        err_div.hide();
        succ_div.hide();

        if ($(modal_id).is(':visible') && modal_id !== '#editaccount1') {
          _clearForm(modal_id);
        }

        return FALSE;
      }
    });
  }

  /**
   *
   * @param id
   * @returns {string}
   * @private
   */
  function _parseUrl(id) {
    let value = window.location.href.split(id + '=')[1];

    if (value) {
      return value.split('&')[0];
    }
  }

  /**
   * This method will parse a JSON string into a JSON object.
   * @param string
   * @param exclusions
   * @returns {string|*}
   * @private
   */
  function _parseJson(string, exclusions) {
    if (_typeOf(string, 'object')) {
      return string;
    }
    try {
      let json = $.parseJSON(string);

      // correct any html entity conversions.
      for (let i in json) {
        // NOTE: we are skipping objects and excluded fields
        if ($.isNumeric(json[i]) || (exclusions && typeof exclusions[i] !== UNDEFINED)) {
          continue;
        }

        json[i] = _parseField(json[i]);
      }

      return json;
    } catch (e) {
      console.log(string);
      return string;
    }
  }

  /**
   * Decode entities detected in a string.
   * @param string The object or string to parse.
   * @private
   * @returns string
   */
  function _parseField(string) {
    let exclusions = ['html_form', 'html_module']; // NOTE: we can parse the html form without breaking the html.

    if (_typeOf(string, 'object')) {
      for (let i in string) {
        if (exclusions.indexOf(i) !== -1 || $.isNumeric(string[i])) {
          continue;
        }

        string[i] = _parseField(string[i]);
      }

      return string;
    }

    return $('<textarea />').html(string).text();
  }

  /**
   * This method will return if the obj is the same type of requested object. Only used
   * as a utility for minification purpose.
   * @param obj The object to check t's type.
   * @param type The type to check for.
   * @private
   * @returns {Boolean}
   */
  function _typeOf(obj, type) {
    return (typeof obj === type);
  }

  /**
   * This method will re-populate a form based on the passed JSON object.
   * Example:
   * {
   * 	'key' : 'value',
   * 	'key' : 'value'
   * }
   * @TODO The element has to have #edit- which shouldn't be.  We should make it more
   * flexible.
   * @param data
   * @param form
   * @private
   */
  function _populateForm(data, form) {
    $.each(data, function (key, value) {
      let elem = form.find('[name=' + key + ']');
      let type = NULL;

      if (_typeOf(elem.prop('tagName'), UNDEFINED)) {
        return;
      }

      if (elem.prop('tagName') === 'INPUT') {
        type = elem.attr('type');
      } else {
        type = (elem.prop('tagName')).toLowerCase();
      }

      switch (type) {
        case "text" :
        case "hidden":
          elem.val(value);
          break;
        case "textarea":
          elem.val(value);
          break;
        case "radio" :
        case "checkbox":
          elem.each(function () {
            let checkbox = $(this);

            if (data[key] === checkbox.val() || data[key] === TRUE) {
              checkbox.prop('checked', TRUE);
            } else {
              checkbox.prop('checked', FALSE);
            }
          });

          break;
        case "select":
          elem.find('option:selected').removeAttr('selected');
          elem.find('option[value="' + value + '"]').prop('selected', TRUE);
          break;
      }
    });
  }

  /**
   *
   * @private
   */
  function _init() {
    BODY_TAG = $('body');
    PAGE_KEY = BODY_TAG.attr('data-page-key');
  }

  /**
   *
   * @private
   */
  function _disableButtons() {
    RC
      .BTN_HANDLER
      .lock(...arguments);
  }

  /**
   *
   * @private
   */
  function _enableButtons() {
    RC
      .BTN_HANDLER
      .unlock(...arguments);
  }

  /**
   *
   * @param table_id
   * @param root_url
   * @param _callbacks
   * @private
   */
  function _deleteRowHandler(table_id, root_url, _callbacks) {
    let TABLE = $(table_id);
    let NORESULTS_ROW = TABLE.find('.noresults');
    let RECORD_ID = 'data-record-id';
    let DELETE_ROW = '#delete_row_';
    let DELETE_FORM = $('#delete_form');
    let CLICK = 'click';
    let callbacks = $.extend(
      {
        'deleteNo': function () {
        },
        'deleteYes': function () {
        }
      },
      _callbacks || {}
    );

    // Opens the delete row for an event.
    TABLE.on(CLICK, '.deleterow_event', function () {
      let row = $(DELETE_ROW + $(this).attr(RECORD_ID));

      if (!row.is(':hidden')) {
        return;
      }

      row.slideDown();
    });

    TABLE.on(CLICK, '.delete_no_link', function () {
      if (RC.BTN_HANDLER.isLocked('#' + this.id)) {
        return;
      }

      let that = $(this);
      let row_id = DELETE_ROW + that.attr('id').replace('delete_no_', '');

      $(row_id).slideUp();
    });

    TABLE.on(CLICK, '.delete_yes_link', function () {
      let that = $(this);
      let id = '#' + that.attr('id');
      let row_id = id.replace('#delete_yes_', '');
      let cancel_id = '#delete_no_' + row_id;
      let token = DELETE_FORM.find('input[name=is_submitted]').val();

      if (RC.BTN_HANDLER.isLocked(id)) {
        return;
      }

      _disableButtons(id, cancel_id);
      _handleAjax(
        {
          info: {
            data: token ? {'is_submitted': token} : {},
            url: root_url + row_id + '/delete/',
            type: token ? 'POST' : 'GET'
          },
          success: function (result) {
            if (result.token) {
              DELETE_FORM.find('input[name=is_submitted]').val(result.token);
            }

            let DELETED_ELEMENT = $(DELETE_ROW + row_id).parents('.row_container');

            DELETED_ELEMENT.fadeOut(function () {
              DELETED_ELEMENT.remove();

              if ($('.row_container').length === 0) {
                NORESULTS_ROW.fadeIn();
              } else {
                _restripeRows();
              }

              callbacks['deleteYes'](row_id, that);
            });
          }
        }
      );
    });
  }

  /**
   *
   * @param PAGES
   */
  RC.loadPage = function (PAGES) {
    if (PAGES.hasOwnProperty(PAGE_KEY)) {
      // Add a onload check b/c modals kept failing in IE.
      window.onload = function () {
        PAGES[PAGE_KEY]();
      };
    }
  };

  /**
   *
   * @returns {*}
   * @constructor
   */
  RC.PAGE_KEY = function () {
    return PAGE_KEY;
  };
  RC.BTN_HANDLER = new _BtnHandler();
  RC.deleteRowHandler = _deleteRowHandler;
  RC.init = _init;
  RC.restripeRows = _restripeRows;
  RC.clearForm = _clearForm;
  RC.closePopup = _closePopup;
  RC.openPopup = _openPopup;
  RC.isSuccess = _isSuccess;
  RC.isSuccessPageForm = _isSuccessPageForm;
  RC.editMyAccount = _editMyAccount;
  RC.globalModals = _globalModals;
  RC.parseUrl = _parseUrl;
  RC.disableButtons = _disableButtons;
  RC.enableButtons = _enableButtons;
  RC.handleAjax = _handleAjax;
  RC.getJsonForm = _getJsonForm;
  RC.populateForm = _populateForm;
  RC.parseJson = _parseJson;

  b.listing = RC;
};

export default FWTK('events', 'ajax', 'listing', 'components', function (fw) {
  let RC = fw.listing;

  RC.init();

  // Added on 5/16/2015 by Omar D. Ellis. I added this b/c the change password fields were getting
  // pre-populated by the browser.
  $('input[type="password"]').focus(function (e) {
    $(this).removeAttr('readonly');
  });

  $('.changelink-dd').each(function (e) {
    let parent = $(this);
    let changesite = parent.find('.changesite-dd');
    let changesub = parent.find('.changesub-dd');

    changesite.click(function (e) {
      e.preventDefault();

      if (!changesub.is(':visible')) {
        $('.changesub-dd').slideUp();

        changesub.slideDown();
      }

      $(document).click(function (e) {
        if (e.target.className.indexOf('changesite-dd') === -1) {
          $('.changesub-dd').slideUp();
          // Hide all drop downs
          $(this).unbind(e);
        }
      });
    });
  });

  $('body').on('hover', '.btn_hover', function (e) {
    let me = $(this);

    me[0].src = me.attr('data-' + ((e.type === 'mouseenter') ? 'over' : 'off'));
  });

  $('.error_close').click(function (e) {
    e.preventDefault();
    $('.errormsg').fadeOut();
  });

  fw.components('passwordstrength');
  fw.passwordstrength({
    template: '',
    ids: {
      // Theses are the ids or class for the components for the password
      // strength test. Look at the jquery.passwordstrength for defaults.
      input: '#changepw1',
      text: '#strength_header',
      meter: '#strength_meter'
    },
    default_message: 'Your password must be at least 4 characters.',
    minimum_length: 4,
    scores: [
      {score: 24, text: 'Weak', color: '#df3a3a'},
      {score: 48, text: 'Fair', color: '#df753a'},
      {score: 72, text: 'Good', color: '#f0a618'},
      {score: 96, text: 'Strong', color: '#85c12f'}
    ],
    rules: {
      minimum_score: 7
    },
    resetCallback: function () {
      $('#strength-header').hide();
      $('#strength-meter li').removeAttr('class');
    },
    passCallback: function (score, styling) {
      $('#strength-meter li').removeAttr('class');
      if (typeof score == 'undefined') return;

      $('#strength-header')
        .html(`Strength: <span class="${styling.text.toLowerCase()}">${styling.text}</span>`)
        .show();

      let strengths = {
        'Weak': 1,
        'Fair': 2,
        'Good': 3,
        'Strong': 4
      };

      $('#strength-meter li').each(function (index, element) {
        if (strengths[styling.text] > index) {
          $(element)
            .removeClass('weak fair good strong')
            .addClass(styling.text.toLowerCase());
        }
      });
    }
  });

  RC.editMyAccount();
});